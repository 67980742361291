import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { Box, VStack, Heading, Spinner, Text, Flex, IconButton } from '@chakra-ui/react';
import { startOfMonth, endOfMonth, eachDayOfInterval, parseISO, format, isValid, getDay, addMonths, subMonths } from 'date-fns';
import { ro } from 'date-fns/locale';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import axios from 'axios';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const API_URL = process.env.REACT_APP_API_URL;

const socket = io(SOCKET_URL);

const TimeSlotSelection = ({ doctorId, specialtyId, isLoading, onTimeslotSelected }) => {
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTimeslot, setSelectedTimeslot] = useState(null);
    const [dailyTimeslots, setDailyTimeslots] = useState([]);
    const [availableTimeslots, setAvailableTimeslots] = useState([]);
    const [activeDays, setActiveDays] = useState(new Set());
    const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));

    const monthName = format(currentMonth, 'MMMM yyyy', { locale: ro });

    const handleSlotsUpdated = useCallback((updatedSlots) => {
        setAvailableTimeslots(updatedSlots);
        const newActiveDays = calculateActiveDays(updatedSlots);
        setActiveDays(newActiveDays);
        updateDailyTimeslots(updatedSlots, selectedDay);
    }, [selectedDay]);

    useEffect(() => {
        const room = `doctor_${doctorId}_specialty_${specialtyId}`;
        socket.emit('join_room', room);
        socket.on('slots_updated', handleSlotsUpdated);

        return () => {
            socket.emit('leave_room', room);
            socket.off('slots_updated', handleSlotsUpdated);
        };
    }, [doctorId, specialtyId, handleSlotsUpdated]);

    const fetchAvailableTimeslots = useCallback(async (month) => {
        try {
            const response = await axios.get(`${API_URL}/doctor_schedules/available/${doctorId}/${specialtyId}`);
            const slots = response.data.filter(slot => {
                const slotDate = parseISO(slot.date);
                return isValid(slotDate) && slotDate >= startOfMonth(month) && slotDate <= endOfMonth(month);
            });
            setAvailableTimeslots(slots);
            const newActiveDays = calculateActiveDays(slots);
            setActiveDays(newActiveDays);
            updateDailyTimeslots(slots, selectedDay);
        } catch (error) {
            console.error('Error fetching timeslots:', error);
        }
    }, [doctorId, specialtyId, selectedDay]);

    const calculateActiveDays = (slots) => {
        const days = new Set();
        slots.forEach(slot => {
            const slotDate = parseISO(slot.date);
            if (isValid(slotDate) && !slot.is_booked && !slot.is_held) {
                const day = format(slotDate, 'yyyy-MM-dd');
                days.add(day);
            }
        });
        return days;
    };

    const updateDailyTimeslots = (slots, day) => {
        if (!day) {
            setDailyTimeslots([]);
            return;
        }
        const formattedDay = format(day, 'yyyy-MM-dd');
        const timeslotsForDay = slots.filter(slot => {
            const slotDate = parseISO(slot.date);
            return isValid(slotDate) && format(slotDate, 'yyyy-MM-dd') === formattedDay && !slot.is_held;
        });
        setDailyTimeslots(timeslotsForDay);
    };

    useEffect(() => {
        fetchAvailableTimeslots(currentMonth);
    }, [doctorId, specialtyId, currentMonth, fetchAvailableTimeslots]);

    const handleDayClick = (day) => {
        setSelectedDay(day);
        updateDailyTimeslots(availableTimeslots, day);
    };

    const onTimeslotClick = (timeslot) => {
        if (selectedTimeslot?.id === timeslot.id) {
            setSelectedTimeslot(null);
            onTimeslotSelected(null);
        } else {
            setSelectedTimeslot(timeslot);
            onTimeslotSelected(timeslot);
        }
    };

    const handleNextMonth = () => {
        const newMonth = addMonths(currentMonth, 1);
        if (newMonth <= addMonths(new Date(), 2)) {
            setCurrentMonth(newMonth);
            setSelectedDay(null); // Reset selected day when changing month
            setSelectedTimeslot(null);
            setDailyTimeslots([]); // Clear timeslots when changing month
        }
    };

    const handlePreviousMonth = () => {
        const newMonth = subMonths(currentMonth, 1);
        if (newMonth >= startOfMonth(new Date())) {
            setCurrentMonth(newMonth);
            setSelectedDay(null); // Reset selected day when changing month
            setSelectedTimeslot(null);
            setDailyTimeslots([]); // Clear timeslots when changing month
        }
    };

    const renderTimeslotsForDay = () => {
        if (isLoading) return <Spinner size="xl" />;
        if (dailyTimeslots.length === 0) return <Box p={2}>Nu sunt sloturi disponibile pentru această zi.</Box>;

        return (
            <VStack align="stretch" w='100%'>
                {dailyTimeslots.map((slot, index) => (
                    <Box
                        key={index}
                        p={2}
                        bg={selectedTimeslot?.id === slot.id ? 'green.400' : 'blue.200'}
                        borderRadius="md"
                        align='center'
                        fontWeight='bold'
                        cursor="pointer"
                        onClick={() => onTimeslotClick(slot)}
                    >
                        {formatTime(slot.start_time)}
                    </Box>
                ))}
            </VStack>
        );
    };

    const formatTime = (time) => time.split(':').slice(0, 2).join(':');

    const renderCalendarGrid = () => {
        const daysInMonth = eachDayOfInterval({ start: startOfMonth(currentMonth), end: endOfMonth(currentMonth) });
        const firstDayIndex = (getDay(startOfMonth(currentMonth)) + 6) % 7; // for Monday start !!

        return (
            <Box display={{ base: 'grid', md: 'grid' }} gridTemplateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(7, 1fr)' }} gap={2} textAlign="center" w='100%'>
                {Array(firstDayIndex).fill(null).map((_, index) => (
                    <Box key={`empty-${index}`} />
                ))}
                {daysInMonth.map((day, index) => {
                    const dayKey = format(day, 'yyyy-MM-dd');
                    const isActive = activeDays.has(dayKey);
                    return (
                        <Box
                            key={index}
                            p={2}
                            mb={{ base: '3' }}
                            bg={isActive ? 'teal.200' : 'gray.100'}
                            cursor={isActive ? 'pointer' : 'not-allowed'}
                            onClick={isActive ? () => handleDayClick(day) : undefined}
                            borderRadius="md"
                            _hover={{ bg: isActive ? 'teal.300' : 'gray.100' }}
                            transition="background-color 0.3s"
                        >
                            <Text fontSize="md" fontWeight={isActive ? 'bold' : 'normal'}>
                                {format(day, 'dd')}
                            </Text>
                            <Text fontSize="sm" color="gray.600">
                                {format(day, 'EEEE', { locale: ro })}
                            </Text>
                        </Box>
                    );
                })}
            </Box>
        );
    };

    return (
        <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="center" w="100%" mt={3} >
            <Box borderWidth="1px" borderColor="gray.300"
                 w={{ base: '100%', md: '70%' }}
                 maxW={{ base: '100%', md: '55%' }}
                 boxShadow="md" p={6} borderRadius="md"
                 bg="white" mb={{ base: 4, md: 0 }} mr={{ md: 4 }}>
                <Flex justify="space-between" align="center" mb={4}>
                    <IconButton
                        icon={<ArrowBackIcon />}
                        onClick={handlePreviousMonth}
                        isDisabled={currentMonth <= startOfMonth(new Date())}
                        aria-label={'arrowback'}
                    />
                    <Heading size="md" textAlign="center">{monthName}</Heading>
                    <IconButton
                        icon={<ArrowForwardIcon />}
                        onClick={handleNextMonth}
                        isDisabled={currentMonth >= addMonths(startOfMonth(new Date()), 2)}
                        aria-label={'arrowforward'}/>
                </Flex>

                <Box overflow="auto" maxH="65vh">
                    {renderCalendarGrid()}
                </Box>
            </Box>
            <Box borderWidth="1px" borderColor="gray.300" w={{ base: '100%', md: '70%' }}
                 maxW={{ base: '100%', md: '25%' }}
                 boxShadow="md" p={6} borderRadius="md" bg="white">
                <Heading size="md" mb={4} w='100%' textAlign="center">
                    {selectedDay ? format(selectedDay, 'eeee, d MMMM', { locale: ro }) : 'Selectează o zi'}
                </Heading>
                <Box overflowY="auto" maxH="65vh">
                    {renderTimeslotsForDay()}
                </Box>
            </Box>
        </Flex>
    );
};

export default TimeSlotSelection;
