//App.js
import React from 'react';
import {ChakraProvider}
    from '@chakra-ui/react';
import Navbar from "./components/navbar";
import Step2 from './components/Step2.js'
function App() {

  return (
      <ChakraProvider>
          <Navbar/>
          <Step2 />
      </ChakraProvider>

  );
}
export default App;

