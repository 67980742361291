import React, { useEffect, useState } from 'react';
import { Box, VStack, Heading, Text, Button, useToast, Image, HStack, Divider, Flex } from '@chakra-ui/react';
import { Calendar, Clock, User, Phone, Mail, FileText, CheckCircle, Edit2, Download, Home,Stethoscope } from 'lucide-react';
import io from 'socket.io-client';
import { format } from "date-fns";
import { ro } from "date-fns/locale";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../assets/logo_crdm.png';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const socket = io(SOCKET_URL);
const ConfirmationStep = ({ appointmentDetails, onEdit }) => {
    const { specialtyName, doctorName, doctorSurname, formData } = appointmentDetails;
    const toast = useToast();
    const [isConfirmed, setIsConfirmed] = useState(false);

    const convertUTCDateToLocalDate = (dateStr) => {
        const date = new Date(dateStr);
        const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        const offset = date.getTimezoneOffset() / 60;
        const hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    };

    const handleConfirm = () => {
        console.log("Emitting confirmation for slot:", appointmentDetails.slotId);
        socket.emit('confirm_reservation', {
            doctorId: appointmentDetails.doctorId,
            specialtyId: appointmentDetails.specialtyId,
            date: appointmentDetails.date,
            startTime: appointmentDetails.startTime,
            endTime: appointmentDetails.endTime,
            patientName: appointmentDetails.formData.name,
            patientSurname: appointmentDetails.formData.surname,
            patientPhone: appointmentDetails.formData.phoneNumber,
            patientEmail: appointmentDetails.formData.email,
            slotId: appointmentDetails.slotId,
        });
    };

    const generatePDF = async () => {
        const pdfDoc = new jsPDF({
            format: 'a5',
            unit: 'mm',
            orientation: 'portrait'
        });

        const element = document.getElementById('pdf-content');
        const canvas = await html2canvas(element, {
            scale: 3,
            useCORS: true,
            logging: false,
            allowTaint: true
        });
        const imgData = canvas.toDataURL('image/png');

        const pdfWidth = pdfDoc.internal.pageSize.getWidth();
        const pdfHeight = pdfDoc.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        const scaledWidth = imgWidth * ratio;
        const scaledHeight = imgHeight * ratio;
        const imgX = (pdfWidth - scaledWidth) / 2;
        const imgY = 10; // Adjust this value to move the content up or down

        pdfDoc.addImage(imgData, 'PNG', imgX, imgY, scaledWidth, scaledHeight);
        pdfDoc.save('appointment_confirmation.pdf');
    };

    useEffect(() => {
        const onReservationConfirmed = () => {
            toast({
                title: "Reservation Confirmed",
                description: `Your appointment is confirmed.`,
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setIsConfirmed(true);
        };
        socket.on('reservation_confirmed', onReservationConfirmed);
        return () => {
            socket.off('reservation_confirmed', onReservationConfirmed);
        };
    }, [toast]);

    useEffect(() => {
        const handleError = (error) => {
            if (error.type === 'confirm_reservation_error') {
                toast({
                    title: "Error",
                    description: error.message || "Failed to confirm reservation",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        socket.on('error', handleError);

        return () => {
            socket.off('error', handleError);
        };
    }, [toast]);

    const redirectToMainPage = () => {
        setTimeout(() => {
            window.location.href = '/admin/home';
        }, 1000);
    };

    const InfoRow = ({ icon, label, value }) => (
        <Flex justify="center" align="center" width="100%">
            <HStack spacing={4} align="center" width="100%" maxWidth="400px">
                <Box width="24px" ml='4' textAlign="center">
                    {icon}
                </Box>
                <Text fontWeight="bold" width="120px">{label}:</Text>
                <Text flex="1">{value}</Text>
            </HStack>
        </Flex>
    );

    return (
        <Box
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="xl"
            boxShadow="xl"
            bg="white"
            p={8}
            maxWidth="500px"
            margin="auto"
        >
            <VStack spacing={6} align="stretch" id="pdf-content" mb='2'>
                <Flex justify="space-between" align="center">
                    <Image src={logo} alt="Institution Logo" height="40px" pr='1'/>
                    <Heading size="lg" color="teal.600">Confirmare Programare</Heading>
                </Flex>

                <Divider />

                <Box pb='4'>
                    <VStack spacing={4} align="stretch">
                        <InfoRow icon={<Stethoscope size={20} color="#4FD1C5" />} label="Specialitate" value={specialtyName} />
                        <InfoRow icon={<User size={20} color="#4FD1C5" />} label="Doctor" value={`${doctorName} ${doctorSurname}`} />
                        <InfoRow
                            icon={<Calendar size={20} color="#4FD1C5" />}
                            label="Data"
                            value={format(convertUTCDateToLocalDate(appointmentDetails.date), 'PPPP', { locale: ro })}
                        />
                        <InfoRow icon={<Clock size={20} color="#4FD1C5" />} label="Ora" value={appointmentDetails.startTime} />
                        <InfoRow icon={<User size={20} color="#4FD1C5" />} label="Nume" value={formData.name} />
                        <InfoRow icon={<User size={20} color="#4FD1C5" />} label="Prenume" value={formData.surname} />
                        <InfoRow icon={<Phone size={20} color="#4FD1C5" />} label="Telefon" value={formData.phoneNumber} />
                        <InfoRow icon={<FileText size={20} color="#4FD1C5" />} label="IDNP" value={formData.IDNP} />
                        <InfoRow icon={<Mail size={20} color="#4FD1C5" />} label="Email" value={formData.email} />
                    </VStack>
                </Box>
            </VStack>
            <Divider />

            <VStack spacing={4} mt={6}>
                <HStack spacing={4} justifyContent="center">
                    <Button
                        leftIcon={<CheckCircle size={20} />}
                        colorScheme="teal"
                        onClick={handleConfirm}
                        isDisabled={isConfirmed}
                    >
                        Confirmă
                    </Button>
                    <Button
                        leftIcon={<Edit2 size={20} />}
                        variant="outline"
                        colorScheme="teal"
                        onClick={onEdit}
                        isDisabled={isConfirmed}
                    >
                        Modifică
                    </Button>
                </HStack>

                {isConfirmed && (
                    <HStack spacing={4} justifyContent="center">
                        <Button
                            leftIcon={<Download size={20} />}
                            colorScheme="blue"
                            onClick={generatePDF}
                        >
                            Salvează PDF
                        </Button>
                        <Button
                            leftIcon={<Home size={20} />}
                            colorScheme="green"
                            onClick={redirectToMainPage}
                        >
                            Pagina Principală
                        </Button>
                    </HStack>
                )}
            </VStack>
        </Box>
    );
};

export default ConfirmationStep;