import React, { useEffect, useState } from 'react';
import {
    VStack, Divider, Flex, Button, SimpleGrid, Box, Heading,
    Text, Image, Card, CardHeader, CardBody,
    Avatar, Input, InputLeftElement, InputGroup, Grid, GridItem
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import '../styles/styles.css';
import axios from "axios";
import TimeSlotSelection from './TimeSlotSelection';
import Step1 from './Step1';
import ConfirmationStep from './ConfirmationStep';
import { format } from "date-fns";
import { Steps } from 'primereact/steps';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import io from "socket.io-client";

const API_URL = process.env.REACT_APP_API_URL;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const socket = io(SOCKET_URL);

function Step2() {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        phoneNumber: '',
        IDNP: '',
        email: '',
        confirm_email: '',
        service: '',
        date: '',
        time: '',
    });

    const [specialties, setSpecialties] = useState([]);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [doctorProfiles, setDoctorProfiles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentStep, setCurrentStep] = useState('selectSpecialty');
    const [selectedDoctor, setSelectedDoctor] = useState({});
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [appointmentDetails, setAppointmentDetails] = useState({});

    const [timeslotToRelease, setTimeslotToRelease] = useState(null);

    const [formValid, setFormValid] = useState(false);

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    const handleResize = () => {
        setIsMobileView(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleTimeslotSelected = (timeslot) => {
        setSelectedTimeSlot(timeslot);
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.7 }
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.7 }
        }
    };

    const stepItems = [
        { label: 'Alege o Specialitate' },
        { label: 'Selectează un Doctor ' },
        { label: 'Timpul Dorit' },
        { label: 'Datele Personale' },
        { label: 'Confirmarea Detaliilor' },
    ];

    const getCurrentStepIndex = () => {
        switch (currentStep) {
            case 'selectSpecialty': return 0;
            case 'selectDoctor': return 1;
            case 'selectTimeSlot': return 2;
            case 'fillForm': return 3;
            case 'confirmAppointment': return 4;
            default: return 0;
        }
    };

    const renderStepLabels = () => {
        if (isMobileView) {
            const currentStepIndex = getCurrentStepIndex();
            return (
                <Box textAlign="center" mt={4} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">
                        {currentStepIndex + 1}. {stepItems[currentStepIndex].label}
                    </Text>
                </Box>
            );
        } else {
            return (
                <Steps model={stepItems} activeIndex={getCurrentStepIndex()} style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }} />
            );
        }
    };

    useEffect(() => {
        const fetchSpecialties = async () => {
            try {
                const response = await axios.get(`${API_URL}/specialties`);
                // Sort the specialties alphabetically by name
                const sortedSpecialties = response.data.filter(s => s.is_active).sort((a, b) => a.name.localeCompare(b.name));
                setSpecialties(sortedSpecialties);
            } catch (error) {
                console.error('Failed to fetch specialties:', error);
            }
        };
        fetchSpecialties();
    }, []);

    const handleSelectSpecialty = async (specialtyId) => {
        setSelectedSpecialty(specialtyId);
        try {
            const response = await axios.get(`${API_URL}/doctor_profiles/specialty/${specialtyId}`);
            setDoctorProfiles(response.data);
        } catch (error) {
            console.error('Failed to fetch doctor profiles:', error);
            setDoctorProfiles([]);
        }
        setCurrentStep('selectDoctor');
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredSpecialties = specialties.filter((specialty) =>
        specialty.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleViewProfile = async (doctorId, specialtyId) => {
        try {
            await axios.get(`${API_URL}/doctor_schedules/available/${doctorId}/${specialtyId}`);
            setSelectedDoctor(doctorProfiles.find(doctor => doctor.id === doctorId));
            setSelectedSpecialty(specialtyId);
            setCurrentStep('selectTimeSlot');
        } catch (error) {
            console.error('Error fetching timeslots:', error);
        }
    };

    const handleBackClick = () => {
        if (currentStep === 'fillForm' && selectedTimeSlot) {
            socket.emit('release_slot', selectedTimeSlot.id);
            setSelectedTimeSlot(null);
        }
        switch (currentStep) {
            case 'selectDoctor':
                setCurrentStep('selectSpecialty');
                setSelectedSpecialty(null);
                break;
            case 'selectTimeSlot':
                setCurrentStep('selectDoctor');
                break;
            case 'fillForm':
                setCurrentStep('selectTimeSlot');
                break;
            case 'confirmAppointment':
                setCurrentStep('fillForm');
                break;
            default:
                break;
        }
    };

    const renderHeading = () => {
        switch (currentStep) {
            case 'selectSpecialty':
                return 'Selectează Specialitatea';
            case 'selectDoctor':
                return 'Selectează Medicul';
            case 'selectTimeSlot':
                return 'Selectează Data și Ora';
            case 'fillForm':
                return 'Completează Formularul';
            case 'confirmAppointment':
                return 'Pasul Final';
            default:
                return 'Selectează Specialitatea';
        }
    };

    const handleTimeSlotSelection = (timeslot) => {
        if (selectedTimeSlot) {
            socket.emit('release_slot', selectedTimeSlot.id);
        }
        socket.emit('hold_slot', timeslot.id);
        setSelectedTimeSlot({
            ...timeslot,
            start_time: timeslot.start_time,
            end_time: timeslot.end_time
        });
        setCurrentStep('fillForm');
    };

    useEffect(() => {
        if (timeslotToRelease) {
            socket.emit('release_slot', timeslotToRelease);
            setTimeslotToRelease(null);
        }
    }, [timeslotToRelease]);

    const handleConfirmAppointment = (reservationDetails) => {
        socket.emit('confirm_reservation', reservationDetails);

        socket.on('reservation_confirmed', (response) => {
            alert(`Your appointment on ${response.result.date} at ${response.result.time} is confirmed.`);
        });

        socket.on('error', (error) => {
            alert(`Error: ${error.message}`);
        });
    };

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        return `${hours}:${minutes}`;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (!formValid) {
            alert('Vă rugăm să verificați formularul pentru erori. Asigurați-vă că toate câmpurile sunt completate corect.');
            return;
        }

        let appointmentDate = '';
        let appointmentTime = '';

        const specialty = specialties.find(s => s.id === selectedSpecialty);
        const doctor = selectedDoctor;
        if (selectedTimeSlot && selectedTimeSlot.date) {
            const date = new Date(selectedTimeSlot.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            appointmentDate = format(utcDate, 'yyyy-MM-dd');
        }

        const appointmentDetails = {
            doctorId: doctor.id,
            specialtyId: specialty.id,
            specialtyName: specialty.name,
            doctorName: doctor.name,
            doctorSurname: doctor.surname,
            appointmentDate: appointmentDate,
            appointmentTime: appointmentTime,
            date: appointmentDate,
            startTime: formatTime(selectedTimeSlot.start_time),
            endTime: formatTime(selectedTimeSlot.end_time),
            patientName: formData.name,
            patientSurname: formData.surname,
            patientPhone: formData.phoneNumber,
            patientEmail: formData.email,
            slotId: selectedTimeSlot.id,
            formData: formData
        };
        setAppointmentDetails(appointmentDetails);
        setCurrentStep('confirmAppointment');
    };

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 'selectSpecialty':
                return renderSpecialtiesGrid();
            case 'selectDoctor':
                if (doctorProfiles.length === 0) {
                    return <Box p={2}>La moment nu există specialiști pentru această specialitate.</Box>;
                } else {
                    return renderDoctorProfiles();
                }
            case 'selectTimeSlot':
                if (selectedDoctor && selectedSpecialty) {
                    return (
                        <TimeSlotSelection
                            doctorId={selectedDoctor.id}
                            specialtyId={selectedSpecialty}
                            isLoading={false}
                            onTimeslotSelected={handleTimeslotSelected}
                        />
                    );
                } else {
                    return <Text>Vă rugăm să selectați un medic și o specialitate pentru a vizualiza intervalele orare disponibile.</Text>;
                }
            case 'fillForm':
                return <Step1
                    formData={formData}
                    setFormData={setFormData}
                    setFormValid={setFormValid}
                />;
            case 'confirmAppointment':
                return <ConfirmationStep
                    appointmentDetails={appointmentDetails}
                    onConfirm={handleConfirmAppointment}
                />;
            default:
                break;
        }
    };

    const renderSpecialtiesGrid = () => (
        <AnimatePresence>
            {selectedSpecialty === null && (
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <VStack width="100%">
                        <InputGroup width="100%" mb={4}>
                            <InputLeftElement pointerEvents="none">
                                <SearchIcon color="gray.400" />
                            </InputLeftElement>
                            <Input
                                type="text"
                                placeholder="Caută specialitate"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                borderRadius="md"
                            />
                        </InputGroup>
                    </VStack>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={5}>
                        {filteredSpecialties.map((specialty) => (
                            <Card
                                key={specialty.id}
                                p={0}
                                borderWidth="1px"
                                borderColor="gray.300"
                                className="specialty-card"
                                onClick={() => handleSelectSpecialty(specialty.id)}
                            >
                                <CardHeader p={0} mt={'20px'}>
                                    <Heading size='md' align='center'>
                                        {specialty.name}
                                    </Heading>
                                </CardHeader>
                                <CardBody>
                                    <Image
                                        src={`${API_URL}/uploads/${specialty.image_url}`}
                                        alt={`${specialty.name}`}
                                        boxSize={{ base: '240px', md: '200px' }}
                                        objectFit='cover'
                                        m='auto'
                                        borderRadius='md'
                                    />
                                    <Text className='specialty-description' mt={2} fontSize='15px'>
                                        {specialty.description}
                                    </Text>
                                    <Button colorScheme='linkedin' variant='outline' mt={4} size='sm' align="center">
                                        Selectați specialistul
                                    </Button>
                                </CardBody>
                            </Card>
                        ))}
                    </SimpleGrid>
                </motion.div>
            )}
        </AnimatePresence>
    );

    const renderDoctorProfiles = () => (
        <AnimatePresence>
            {selectedSpecialty !== null && (
                <motion.div variants={containerVariants} initial="hidden" animate="visible" exit="exit">
                    <Box width="100%" p={5} shadow="md" borderWidth="1px">
                        <Flex direction="column" alignItems="center">
                            <Heading fontSize="xl" mb={4}>
                                {specialties.find((s) => s.id === selectedSpecialty)?.name}
                            </Heading>
                        </Flex>
                        <Divider my={4} />
                        <VStack align="start" spacing={4} justify="center" alignItems="center" width="100%">
                            {doctorProfiles.map((doctor, index) => (
                                <Flex
                                    key={index}
                                    direction={{ base: 'column', md: 'row' }}
                                    shadow="md"
                                    borderColor="blue.400"
                                    borderWidth="2px"
                                    p={3}
                                    borderRadius="md"
                                    width="100%"
                                    maxW="800px"
                                    alignItems="center"
                                    bg="white"
                                    mb={4}
                                >
                                    <Avatar
                                        src={doctor.image_url ? `${API_URL}${doctor.image_url}` : 'default-avatar.png'}
                                        alt={doctor.name}
                                        boxSize="90px"
                                        borderRadius="full"
                                        showBorder={true}
                                        borderColor="blue.400"
                                        borderWidth="2px"
                                        shadow="md"
                                        mb={{ base: 3, md: 0 }}
                                        mr={{ md: 4 }}
                                    />
                                    <VStack align={{ base: 'center', md: 'start' }} flex="1" px={4}>
                                        <Text fontWeight="bold" fontSize="lg" textAlign={{ base: 'center', md: 'left' }}>
                                            {doctor.name} {doctor.surname}
                                        </Text>
                                        <Text fontSize="sm" color="gray.600" textAlign={'justify'}>
                                            {doctor.description || 'NU există descriere la moment.'}
                                        </Text>
                                    </VStack>
                                    <Button
                                        size="md"
                                        colorScheme="teal"
                                        onClick={() => handleViewProfile(doctor.id, selectedSpecialty)}
                                        mt={{ base: 3, md: 0 }}
                                    >
                                        Vezi Orarul
                                    </Button>
                                </Flex>
                            ))}
                        </VStack>
                    </Box>
                </motion.div>
            )}
        </AnimatePresence>
    );

    return (
        <Flex alignItems="center" justifyContent="center" margin="0 auto" padding="0" direction="column" width="100%" mb='50'>
            <Box width="100%" maxW="1200px" px={4}>
                {renderStepLabels()}
            </Box>
            <Divider pb={3} mb={5} />
            <VStack width="100%">
                <Grid
                    templateColumns="repeat(3, 1fr)"
                    alignItems="center"
                    width="100%"
                    px={4}
                >
                    <GridItem colSpan={1}>
                        {currentStep !== 'selectSpecialty' && (
                            <Button
                                ml='25%'
                                leftIcon={<ArrowBackIcon />}
                                size="sm"
                                variant="outline"
                                onClick={handleBackClick}
                            >
                                Înapoi
                            </Button>
                        )}
                    </GridItem>
                    <GridItem colSpan={1} textAlign="center" className={'renderHeading'}>
                        <Heading size="lg">{renderHeading()}</Heading>
                    </GridItem>
                    <GridItem colSpan={1} visibility={currentStep === 'selectSpecialty' ? 'hidden' : 'visible'}>
                        <Button size="sm" visibility="hidden">Placeholder</Button>
                    </GridItem>
                </Grid>
                <Divider pb={3} mb={5} />
            </VStack>
            {renderCurrentStep()}
            {currentStep === 'selectTimeSlot' && (
                <Button
                    mt='5'
                    size="md"
                    variant='solid'
                    colorScheme='blue'
                    onClick={() => handleTimeSlotSelection(selectedTimeSlot)}
                    isDisabled={!selectedTimeSlot}
                >
                    Continue
                </Button>
            )}
            {currentStep === 'fillForm' && (
                <Button
                    mt='5'
                    size="md"
                    variant='solid'
                    colorScheme='blue'
                    onClick={handleFormSubmit}
                >
                    Confirmă Programarea
                </Button>
            )}
        </Flex>
    );
}

export default Step2;
