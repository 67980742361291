//Step1.js

import React, { useEffect, useState } from 'react';
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from 'libphonenumber-js';
import "react-phone-input-2/lib/bootstrap.css";
import {
    Container, VStack,
    Input, FormControl,
    FormLabel, Stack,
    useColorModeValue,
    FormErrorMessage
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';


const inappropriateWords = [
    // Common
    'insult', 'offensive', 'rude', 'vulgar', 'profanity','root','admin',

    // Romanian and Moldavian specific
    'înjurătură','hui','pizda','pidor','gandon','vaflist','eblan','concenii',
    'vafledron', 'obscen', 'vulgar', 'prost', 'idiot','pidarprost','huila','huilă',
    'cretin', 'tâmpit', 'păcălici', 'fraier', 'gogoman','jivnasti','ueban','concenîi',
    'nenorocit', 'măgăoaie', 'lache', 'bădăran', 'sifon','loh','loshara','loșara','huesos','pidr','pidrila',
    'lepra', 'parazit', 'scârbă', 'putoi', 'gunoi',
    'cacat', 'pisat', 'futut', 'cur',
    'futtut', 'idioț', 'kretin', 'tampit',
    'tâmpiț', 'magaoaie', 'gogomăn', 'nenorocitu', 'lacheu',
    'badaran', 'pacalici', 'fraer', 'sifonn', 'leprra',
    'parazzit', 'scarba', 'puttoi', 'gunoii', 'cacatt', 'pisatt',
    'zapciu', 'zăpăciu', 'prostu satului', 'găozar', 'găinar',
    'șmecher', 'șmecherie', 'muflon', 'muflonzii', 'mamaligar', 'mamăligar',
    'buhai', 'buhăi', 'cioban', 'ciobănime', 'boschetar', 'boschetărie',
    'mârlan', 'mârlănie', 'betiv', 'betivan', 'betivăn', 'papuc',
    'lighioană', 'lighioi', 'mitocan', 'mitocănie', 'drăcăi', 'dracălău',
    'coate-goale', 'ciorditor', 'șarlatan', 'nătărău', 'bășinos',
    'ciotcă', 'ciung', 'borât', 'pricăjit', 'beldie', 'beldime',
    'lingău', 'chior', 'scatoalcă', 'scârtar', 'tulai',

    // English specific
    'bitch', 'bastard', 'shit', 'fuck', 'cunt',
    'dick', 'cock', 'pussy', 'asshole', 'fag',
    'faggot', 'damn', 'crap', 'piss', 'douche',
    'slut', 'whore', 'prick', 'bullshit', 'arsehole',
    'sonofabitch', 'jackass', 'dumbass', 'shithead', 'fuckface',
    'butthole', 'screwup', 'jerk', 'moron',
    'retard', 'twat', 'wanker', 'bloodyhell', 'bollocks',
    'bugger', 'arse', 'git', 'tosser', 'fuckwit',
    'dipshit', 'goober', 'numbnuts', 'scumbag', 'turd',

    // Russian specific
    'оскорбление', 'грубое', 'вульгарное', 'непристойное',
    'сука', 'сучка', 'блядь', 'пизда', 'хуй',
    'член', 'жопа', 'мудак', 'гандон', 'пидар',
    'пидорас', 'ебать', 'ебаный', 'дерьмо', 'говно',
    'нахуй', 'наебать', 'хуесос', 'еблан', 'шлюха',
    'шалава', 'проститутка', 'козел', 'идиот', 'урод',
    'пиздец', 'хер', 'жопа', 'срань', 'ссать',
    'мразь', 'ублюдок', 'лох', 'блять', 'сукин сын',
    'мудила', 'говнюк', 'пиздабол', 'пидор', 'ебало',
    'хуйня', 'жополиз', 'долбоеб', 'ебанат', 'похуй'
];
function containsInappropriateWord(text) {
    const words = text.toLowerCase().split(/\s+/);
    return words.some(word => inappropriateWords.includes(word));
}
function Step1({ formData, setFormData, setFormValid }) {
    const [errors, setErrors] = useState({});

    const validateForm = debounce(() => {
        const newErrors = {};
        // Name validation
        if (!formData.name.trim()) {
            newErrors.name = "Numele este obligatoriu.";
        } else if (!/^[A-Za-zÀ-ÿ\s'-]+$/.test(formData.name)) {
            newErrors.name = "Numele trebuie să conțină doar litere, spații, apostroafe sau cratime.";
        } else if (formData.name.length < 2) {
            newErrors.name = "Numele trebuie să aibă cel puțin 2 caractere.";
        } else if (formData.name.length > 30) {
            newErrors.name = "Numele nu poate depăși 30 de caractere.";
        } else if (containsInappropriateWord(formData.name)) {
            newErrors.name = "Numele conține cuvinte nepotrivite.";
        }

        // Surname validation
        if (!formData.surname.trim()) {
            newErrors.surname = "Prenumele este obligatoriu.";
        } else if (!/^[A-Za-zÀ-ÿ\s'-]+$/.test(formData.surname)) {
            newErrors.surname = "Prenumele trebuie să conțină doar litere, spații, apostroafe sau cratime.";
        } else if (formData.surname.length < 2) {
            newErrors.surname = "Prenumele trebuie să aibă cel puțin 2 caractere.";
        } else if (formData.surname.length > 50) {
            newErrors.surname = "Prenumele nu poate depăși 50 de caractere.";
        } else if (containsInappropriateWord(formData.surname)) {
            newErrors.surname = "Prenumele conține cuvinte nepotrivite.";
        }
        if (!formData.phoneNumber) {
            newErrors.phoneNumber = "Numărul de telefon este obligatoriu.";
        } else if (!isValidPhoneNumber(`+${formData.phoneNumber}`)) {
            newErrors.phoneNumber = "Numărul de telefon nu este valid.";
        }
        if (!validateEmail(formData.email)) newErrors.email = "Adresa de email nu este validă.";
        if (formData.IDNP && (formData.IDNP.length !== 13 || !/^\d+$/.test(formData.IDNP))) {
            newErrors.IDNP = "IDNP trebuie să fie format din exact 13 cifre.";
        } if (formData.email !== formData.confirm_email) newErrors.confirm_email = "Email-urile nu coincid.";

        setErrors(newErrors);
        setFormValid(Object.keys(newErrors).length === 0);
    }, 300);

    useEffect(() => {
        validateForm();
    }, [formData]);

    const capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const handleNameChange = (e) => {
        const value = e.target.value.replace(/[^A-Za-zÀ-ÿ\s-]/g, '');
        setFormData({ ...formData, name: capitalizeFirstLetter(value) });
    };

    const handleSurnameChange = (e) => {
        const value = e.target.value.replace(/[^A-Za-zÀ-ÿ\s-]/g, '');
        setFormData({ ...formData, surname: capitalizeFirstLetter(value) });
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleIDNPChange = (e) => {
        const idnp = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
        if (idnp.length <= 13) {
            setFormData({ ...formData, IDNP: idnp });
        }
    };

    return (
        <Container maxW={{ base: "100%", sm: "90%", md: "75%", lg: "60%", xl: "50%" }} margin="0 auto">
            <VStack
                as="form" w="100%"
                bg={useColorModeValue('white', 'gray.700')}
                rounded="lg" boxShadow="md" border='1px' borderColor='gray.200'
                p={{ base: 4, sm: 7 }} justify="center"
                onChange={validateForm}
            >
                <VStack w="100%" spacing={6} margin="0 auto">
                    <Stack w="100%" spacing={6} direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="name" isInvalid={errors.name}>
                            <FormLabel>Nume</FormLabel>
                            <Input
                                type="text"
                                placeholder="Nume (Familia)"
                                value={formData.name}
                                onChange={handleNameChange}
                                width="100%"
                            />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>

                        <FormControl id="surname" isInvalid={errors.surname}>
                            <FormLabel>Prenume</FormLabel>
                            <Input
                                type="text"
                                placeholder="Prenume"
                                value={formData.surname}
                                onChange={handleSurnameChange}
                                width="100%"
                            />
                            <FormErrorMessage>{errors.surname}</FormErrorMessage>
                        </FormControl>
                    </Stack>

                    <Stack w="100%" spacing={6} direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="phone" isInvalid={errors.phoneNumber} >
                            <FormLabel>Număr de Telefon</FormLabel>
                            <PhoneInput
                                country="md"
                                enableSearch={true}
                                value={formData.phoneNumber}
                                onChange={(phone) => setFormData({ ...formData, phoneNumber: phone })}
                                inputStyle={{
                                    width: '100%',
                                    padding: '0.5rem',
                                    paddingLeft: '55px',
                                    borderColor: 'gray.200'
                                }}
                                inputProps={{
                                    required: true,
                                    placeholder: 'Număr de Telefon',
                                }}
                            />
                            <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                        </FormControl>

                        <FormControl id="IDNP" isInvalid={errors.IDNP}>
                            <FormLabel>Număr Personal</FormLabel>
                            <Input
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                placeholder="IDNP format din 13 cifre"
                                value={formData.IDNP}
                                onChange={handleIDNPChange}
                                width="100%"
                            />
                            <FormErrorMessage>{errors.IDNP}</FormErrorMessage>
                        </FormControl>
                    </Stack>

                    <Stack w="100%" spacing={6} direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="email" isInvalid={errors.email}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type={'email'}
                                placeholder="Poșta Electronică"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                width="100%"
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>

                        <FormControl id="email_confirm" isInvalid={errors.confirm_email}>
                            <FormLabel>Confirmați Email</FormLabel>
                            <Input
                                type={'email'}
                                placeholder="Confirmă Poșta Electronică"
                                value={formData.confirm_email}
                                onChange={(e) => setFormData({ ...formData, confirm_email: e.target.value })}
                                width="100%"
                            />
                            <FormErrorMessage>{errors.confirm_email}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                </VStack>
            </VStack>
        </Container>
    );
}

export default Step1;
